import cx from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { Checked } from '../../icons';

import * as styles from './ImageCheckbox.module.scss';

export const ImageCheckbox = ({ checked, onChange, label, name, icon }) => {
  const [isChecked, setCheck] = useState(checked || false);

  return (
    <div className={styles.root}>
      <div
        className={cx(styles.styledCheckbox, { [styles.checked]: isChecked })}
      />
      <button
        name={name}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          setCheck(!isChecked);
          onChange({ target: { name, value: !isChecked } });
        }}
        tabIndex="0"
      >
        <span className={styles.mainIcon}>{icon}</span>
        <span className={styles.checkedIcon}>
          <Checked />
        </span>
      </button>
      {label}
    </div>
  );
};
