import cx from 'classnames';
import * as React from 'react';
import * as styles from './FlexContainer.module.scss';

export const FlexContainer = props => (
  <div
    className={cx(styles.root, {
      [styles.alignCenter]: props.align === 'center',
      [styles.alignStretch]: props.align === 'stretch',
      [styles.justifyCenter]: props.justify === 'center',
      [styles.wrap]: props.wrap === 'wrap',
      [styles.directionColumn]: props.flexdirection === 'column',
      [styles.gap]: props.gap,
    })}
  >
    {props.children}
  </div>
);
