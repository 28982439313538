import * as React from 'react';

import FlexContainer from '../FlexContainer';

import * as styles from './ImageCheckboxWrapper.module.scss';

export const ImageCheckboxWrapper = ({ children, label }) => (
  <div className={styles.root}>
    <div className={styles.styledLabel}>{label}</div>
    <FlexContainer>{children}</FlexContainer>
  </div>
);
