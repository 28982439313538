import * as React from 'react';
import { useRef, useState } from 'react';

import { navigate } from 'gatsby-link';
import queryString from 'query-string';

import * as dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import useSignUpForm from '../utils/useContactForm';

import Button from '../components/Button';
import Container from '../components/Container';
import Form from '../components/Form';
import ImageCheckbox from '../components/ImageCheckbox';
import ImageCheckboxWrapper from '../components/ImageCheckboxWrapper';
import Layout from '../components/Layout';
import Paragraph from '../components/Paragraph';
import Textfield from '../components/Textfield';

import {
  Cake,
  Checked,
  Cupcake,
  Cookie,
  User,
  Envelope,
  Smartphone,
  Calendar,
  Gift,
  Palette,
} from '../icons';

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const productList = [
  { icon: <Cake />, label: 'Cakes', name: 'cakes' },
  { icon: <Cupcake />, label: 'Cupcakes', name: 'cupcakes' },
  { icon: <Cookie />, label: 'Custom Cookies', name: 'cookies' },
  { icon: <Gift />, label: 'Cookie Club Box', name: 'giftboxes' },
  { icon: <Palette />, label: 'Diy', name: 'diy-cookie-kits' },
];

const requiredFields = ['date', 'name', 'email', 'phone'];

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
const phoneRegex = /^\((\d{3})\)\s(\d{3})-(\d{4})$/;
const dateRegex = /^\d{4}-\d{1,2}-\d{1,2}$/;

dayjs.extend(customParseFormat);

const ContactPage = ({ location }) => {
  const query = queryString.parse(location.search).p;

  const [products, setProducts] = useState(query ? [query] : []);
  const [stateError, setStateError] = useState({});
  const errorFields = useRef([]);

  const initialValues = {
    date: '',
    email: '',
    message: '',
    name: '',
    phone: '',
    products: query ? [query] : [],
    refer: '',
  };

  const setErrorText = (field, errorText) => {
    setStateError(prevProps => ({
      ...prevProps,
      [field]: { error: true, errorText },
    }));
    errorFields.current = [...errorFields.current, field];
  };

  const checkValid = async () => {
    setStateError({});
    errorFields.current = [];

    for await (const field of requiredFields) {
      if (inputs[field] === initialValues[field]) {
        setErrorText(field, 'Please enter the info');
      } else {
        if (field === 'email' && !inputs[field].match(emailRegex)) {
          setErrorText(field, 'Please enter a valid email');
        }

        if (field === 'phone' && !inputs[field].match(phoneRegex)) {
          setErrorText(field, 'Please enter a valid phone number');
        }
        if (
          field === 'date' &&
          (!dayjs(inputs[field], 'YYYY-MM-DD', true).isValid() ||
            !dayjs(inputs[field]).isAfter(dayjs()))
        ) {
          setErrorText(field, 'Please enter a valid date');
        }
      }
    }

    if (errorFields.current.length === 0) {
      return true;
    }
    return false;
  };

  const signup = async event => {
    if ((await checkValid()) === true) {
      const form = event.target;
      const body = encode({
        'form-name': form.getAttribute('name'),
        ...inputs,
      });
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body,
      })
        .then(() => {
          navigate(form.getAttribute('action'));
        })
        .catch(error => console.error(error));
    }
  };

  const { inputs, handleInputChange, handleSubmit } = useSignUpForm(
    initialValues,
    signup,
  );

  const handleProductChange = ({ target }) => {
    const newProducts = target.value
      ? [...products, target.name]
      : products.filter(value => value !== target.name);
    setProducts(newProducts);
    handleInputChange({ target: { name: 'products', value: newProducts } });
  };

  return (
    <Layout pagetitle="Contact" title="Order / Contact">
      <Container>
        <Paragraph>
          If you would like would like to place an order or would like more
          information about Stella Sweets, please complete the form below and we
          will get back to you.
        </Paragraph>
        <Form
          action="/thankyou/?m=contact"
          name="ContactForm"
          noValidate
          onSubmit={handleSubmit}
        >
          <input name="bot-field" onChange={handleInputChange} type="hidden" />
          <ImageCheckboxWrapper label="Ordering? What would you like? (check all that apply)">
            {productList.map(product => (
              <ImageCheckbox
                checked={product.name === query}
                icon={product.icon}
                key={product.name}
                label={product.label}
                name={product.name}
                onChange={handleProductChange}
              />
            ))}
          </ImageCheckboxWrapper>
          <input name="products" type="hidden" value={inputs.products} />
          <Textfield
            error={stateError?.date?.error || false}
            errorText={stateError?.date?.errorText || ''}
            icon={<Calendar />}
            label="When would you like your order?"
            name="date"
            onChange={handleInputChange}
            placeholder="mm/dd/yyyy"
            required
            type="date"
            value={inputs.date}
          />

          <Textfield
            error={stateError?.name?.error || false}
            errorText={stateError?.name?.errorText || ''}
            icon={<User />}
            label="Your Name"
            name="name"
            onChange={handleInputChange}
            required
            value={inputs.name}
          />

          <Textfield
            error={stateError?.email?.error || false}
            errorText={stateError?.email?.errorText || ''}
            icon={<Envelope />}
            label="Email"
            name="email"
            noValidate
            onChange={handleInputChange}
            required
            type="email"
            value={inputs.email}
          />
          <Textfield
            error={stateError?.phone?.error || false}
            errorText={stateError?.phone?.errorText || ''}
            icon={<Smartphone />}
            label="Phone (Text/SMS)"
            mask="phone"
            name="phone"
            onChange={handleInputChange}
            required
            type="tel"
            value={inputs.phone}
          />

          <Textfield
            label="Tell me more about your order"
            multiline
            name="message"
            onChange={handleInputChange}
            placeholder="What is the theme? How many people will you serve?"
            value={inputs.message}
          />

          <Textfield
            datalist={[
              'Facebook',
              'Instagram',
              'Google',
              'Yelp',
              'Family',
              'Friend',
              'School',
              'Ad',
            ]}
            icon={<Checked />}
            label="How did you hear about Stella Sweets?"
            name="refer"
            onChange={handleInputChange}
            value={inputs.refer}
          />

          <Button type="submit">Contact Stella Sweets</Button>
        </Form>
      </Container>
    </Layout>
  );
};

export default ContactPage;
