import * as React from 'react';
import * as styles from './Form.module.scss';

export const Form = ({ onSubmit, name, action, children, ...rest }) => (
  <div className={styles.root}>
    <form
      action={action || '/'}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      method="post"
      name={name || 'form'}
      onSubmit={onSubmit}
      {...rest}
    >
      <input name="form-name" type="hidden" value={name || 'form'} />
      {children}
    </form>
  </div>
);
