import { useState } from 'react';

const useSignUpForm = (initialValues, callback) => {
  const [inputs, setInputs] = useState(initialValues);
  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    callback(event);
  };
  const handleInputChange = event => {
    if(event.type) {
      event.persist();
    }
    setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
  };
  return {
    handleSubmit,
    handleInputChange,
    inputs,
  };
};

export default useSignUpForm;
